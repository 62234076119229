import InventoryList from "../../components/inventory card list/InventoryList";

function Inventory(){
    return (
        <div className="m-4">
            <InventoryList/>
        </div>
    )
}

export default Inventory;